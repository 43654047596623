// material-ui
import { Typography } from '@mui/material';

// ==============================|| SAMPLE PAGE ||============================== //

const NotFoundPage = () => (
    <Typography variant="body2">
        <>
            <div className='body-text-center'>
                404 &nbsp; &nbsp;|&nbsp; &nbsp; NOT FOUND
            </div>
        </>
    </Typography>
);

export default NotFoundPage;