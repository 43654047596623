import axios from 'axios';
import helper from './helper';



axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);
    if (process.env.REACT_APP_ENV == 'prod') {
      let url = req.url;
      let reqBaseURL = url.split('/').splice(0, 3).join('/') + '/';
      if (process.env.REACT_APP_URL === reqBaseURL) {
        req.data = { "z": helper.encrypt(req.data) };
      }
    }

    req.headers['authorization'] = localStorage.getItem('authToken');
    let userInfo = helper.decodeJWT(localStorage.getItem('authToken'));
    console.log("userInfo~~~ ", userInfo);
    if (userInfo) {
      req.headers['accountid'] = userInfo.userId;
      req.headers['accountKey'] = userInfo.Key;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);


// For POST requests
if (process.env.REACT_APP_ENV == 'prod') {
  axios.interceptors.response.use(
    async (res) => {
      // Add configurations here
      let url = res.config.url;
      let reqBaseURL = url.split('/').splice(0, 3).join('/') + '/';
      if (process.env.REACT_APP_URL === reqBaseURL) {
        res.data = await JSON.parse(helper.decode(res.data));

      }
      return res;
    },
    async (err) => {
      console.log(' Errr acipher d', err);
      if (err.response && err.response.data)
        err.response.data = await JSON.parse(helper.decode(err.response.data));
      return Promise.reject(err);
    }
  );
}


export default {
  init: () => {
    return axios;
  }
};

