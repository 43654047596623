import axios from "axios"
import apiName from "./api-name"


export default {
    getProcessOrder: (data) => {
        return axios.post(process.env.REACT_APP_URL + apiName.getProcessOrder, data);
    },
    verifyTrans: (data) => {
        return axios.post(process.env.REACT_APP_URL + apiName.verifyTrans, data);
    },
    cancelOrder: (data) => {
        return axios.post(process.env.REACT_APP_URL + apiName.cancelOrder, data);
    },
}