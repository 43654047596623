import CheckoutPage from 'views/checkout-page';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/checkout/:orderId',
  element: <CheckoutPage />
};

export default MainRoutes;
