// material-ui
import { Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react'; // Import useState from React
import { useNavigate } from 'react-router-dom';
import phonePaySVg from '../../assets/images/phonepe.svg';
import bhimupiSVg from '../../assets/images/bhimupi.svg';
import googlepaySVg from '../../assets/images/googlepay.svg';
import otherupiSVg from '../../assets/images/otherupi.svg';
import paytmSVg from '../../assets/images/paytm.svg';
import copySVg from '../../assets/images/copy.svg';
import { isMobile } from "react-device-detect";
import api from 'api';
import { toast } from 'react-toastify';

// ==============================|| SAMPLE PAGE ||============================== //


const CheckoutPage = () => {
  const navigate = useNavigate(); // Initialize the useHistory hook
  let [isShow, setIsShow] = useState(true); // Use state to manage isShow
  let [orderId, setOrderId] = useState('');
  let utrNoSubmit11 = useRef(null);
  let [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  let [TabUPI, setTabUPI] = useState(true);

  let [tmpUtrNo, setTempUtrNo] = useState(null);
  let [orderList, setOrderList] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  let verifyTransInterval;



  const handleToggle = async () => {
    setIsShow(false);

    console.log('utrno temp',);
    utrNoSubmit11.current = tmpUtrNo;
  };



  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60


  // This function will be called when the component mounts
  useEffect(() => {
    // Parse the URL to get the order ID
    const url = window.location.href;
    const parts = url.split('/');
    const orderIdFromUrl = parts[parts.length - 1];
    setOrderId(orderIdFromUrl);

    // Call your API with the extracted order ID
    fetchOrderList(orderIdFromUrl);
    verifyTrans(orderIdFromUrl);
    verifyTransInterval = setInterval(() => {

      verifyTrans(orderIdFromUrl, utrNoSubmit11.current);
    }, 5000);

    return () => {
      console.log('Component will unmount');
      clearInterval(verifyTransInterval);
      // Yahan aap cleanup code likh sakte hain
    };
  }, []); // The empty array [] means this effect only runs once, equivalent to componentDidMount

  /* Get Order List */
  const fetchOrderList = async (orderId) => {
    try {
      // console.log('orderId: ', orderId);
      let resp = await api.processApi.getProcessOrder({ orderId });
      console.log("fetchOrderList:- ", resp.data.data);
      setOrderList(resp.data.data);
      let ct = Math.floor(Date.now() / 1000);
      let exp = parseInt(resp.data.data.expire_at);
      let remainT = exp - ct;
      console.log('remainT: ', remainT);
      if (remainT > 0) {
        setTimeRemaining(remainT);
      }

    } catch (error) {
      console.log('error: ', error);
      console.log('error: ', error.response.data.message);
      navigate('/');
    }
  };
  /* Verify Transaction */
  const verifyTrans = async (orderId, utrNo) => {
    try {
      console.log('utrNo: ', utrNo);
      let resp = await api.processApi.verifyTrans({ orderId, utrNo: utrNo });
      console.log("verifyTrans:- ", resp.data);
      if (resp.data.status) {
        clearInterval(verifyTransInterval);
        window.location.href = resp.data.return_url
      }

    } catch (error) {
      console.log('error: ', error.response ? error.response.data : error);
      if (error.response && error.response.data.return_url) {
        clearInterval(verifyTransInterval);
        window.location.href = error.response.data.return_url
      } else {
        clearInterval(verifyTransInterval);
        navigate('/');
      }
    }
  };


  const cancelOrder = async () => {
    try {
      let resp = await api.processApi.cancelOrder({ order_id: orderId });
      console.log("cancelOrder:- ", resp.data);
      if (resp.data.status) {
        window.location.href = resp.data.return_url
      }
    } catch (error) {
      console.log('error: ', error.response ? error.response.data : error);
      if (error.response && error.response.data.return_url) {
        window.location.href = error.response.data.return_url
      } else {
        navigate('/');
      }
    }
  };

  const handleSetUTR = async (value) => {
    if (value.length == 12)
      setIsSubmitDisabled(false)
    else
      setIsSubmitDisabled(true)


    if (value.length <= 12) {
      setTempUtrNo(value);
    } else {

    }
  };
  const handleTabSwitch = (value) => {
    setTabUPI(value)
  };



  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.info("copy to clipboard")
  };
  return (

    <Typography variant="body2">
      {
        isShow ?
          <>

            <div data-v-80ae6ee1="" className={isMobile ? "" : "checkout center-box-pg"}>
              <div data-v-80ae6ee1="" className="bghead">
                <h3 data-v-80ae6ee1="">Proceed To Pay</h3>
              </div>
              <div data-v-80ae6ee1="" className="text-center">
                <h3 data-v-80ae6ee1="">Expire Time: {orderList ? orderList.expire_at_format.split(" ")[1] + " " + orderList.expire_at_format.split(" ")[2] : null}</h3>
                <div data-v-45fa8a71="" className="base-timer" style={{ display: "inline-flex" }}>
                  <svg
                    data-v-45fa8a71=""
                    className="base-timer__svg"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-v-45fa8a71="" className="base-timer__circle">
                      <circle
                        data-v-45fa8a71=""
                        className="base-timer__path-elapsed"
                        cx={50}
                        cy={50}
                        r={45}
                      />
                      <path
                        data-v-45fa8a71=""
                        id="base-timer-path-remaining"
                        strokeDasharray="257 283"
                        className="base-timer__path-remaining green"
                        d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
                      />
                    </g>
                  </svg>
                  <span
                    data-v-45fa8a71=""
                    id="base-timer-label"
                    className="base-timer__label"
                  >
                    {`${minutes}:${seconds}`} <br data-v-45fa8a71="" />
                    <span data-v-45fa8a71="" className="exspan">
                      mins
                    </span>
                  </span>
                  {/* Expire Time: {orderList ? orderList.expire_at_format.split(" ")[1] + " " + orderList.expire_at_format.split(" ")[2] : null} */}
                </div>
              </div>
              <div data-v-80ae6ee1="" className="cp">
                <div data-v-80ae6ee1="" className="amount">
                  <div data-v-80ae6ee1="">
                    <label data-v-80ae6ee1="">Amount</label>
                    <h1 data-v-80ae6ee1="">₹ {orderList && orderList.ORDER.AMOUNT}</h1>
                  </div>
                  <div data-v-80ae6ee1="" className="orderid">
                    <label data-v-80ae6ee1="">Order ID</label>
                    <p data-v-80ae6ee1="">{orderList && orderList.ORDER.ORDERID}</p>
                  </div>
                </div>
                <div data-v-80ae6ee1="">
                  <span data-v-80ae6ee1="" className="line">
                    <h4 data-v-80ae6ee1="">
                      <span data-v-80ae6ee1="">Express Checkout</span>
                    </h4>
                  </span>
                </div>
                <div data-v-80ae6ee1="" className="pi">
                  <a
                    onClick={() => { handleToggle() }} data-v-80ae6ee1=""
                    href={orderList && orderList.UPIAUTO.phonpe_deeplink}
                  >
                    {/* src={require(`../../assets/images/richpari.png`)} */}
                    <img src={phonePaySVg} />
                  </a>
                  <a
                    onClick={() => { handleToggle() }} data-v-80ae6ee1=""
                    href={orderList && orderList.UPIAUTO.gpay_deeplink}
                  >
                    <img src={googlepaySVg} />
                  </a>
                  <a
                    onClick={() => { handleToggle() }}
                    href={orderList && orderList.UPIAUTO.paytm_deeplink}
                  >
                    <img src={paytmSVg} />
                  </a>
                  {/* <a
                    onClick={() => { handleToggle() }}
                    href={orderList && orderList.UPIAUTO.other}
                    >
                    <img src={bhimupiSVg} />
                  </a> */}
                  <a
                    onClick={() => { handleToggle() }}
                    href={orderList && orderList.UPIAUTO.other}
                  >
                    <img src={otherupiSVg} />
                  </a>
                </div>
                <div data-v-80ae6ee1="" className="linemt">
                  <span data-v-80ae6ee1="" className="line">
                    <h4 data-v-80ae6ee1="">
                      <span data-v-80ae6ee1="">Other Payment Method</span>
                    </h4>
                  </span>
                </div>
                <div data-v-80ae6ee1="" className="ctab">

                  <div data-v-80ae6ee1="" className="tab">
                    <button data-v-80ae6ee1="" onClick={() => { handleTabSwitch(true) }} className={TabUPI ? "active" : ""}>
                      {/* UPI */}
                      UPI
                    </button>
                    <button data-v-80ae6ee1="" onClick={() => { handleTabSwitch(false) }} className={!TabUPI ? "active" : ""}>
                      {/* ONLY IMPS */}
                      ONLY IMPS

                    </button>
                  </div>
                  <div data-v-80ae6ee1="" className="content">
                    <div data-v-80ae6ee1="" className="imps" style={{ display: !TabUPI ? "" : "none" }}>
                      <div data-v-80ae6ee1="" className="df">
                        <form data-v-80ae6ee1="">
                          <div data-v-80ae6ee1="" className="clabel">
                            <label data-v-80ae6ee1="">Bank Name</label>
                            <div data-v-80ae6ee1="" className="cpy-text">
                              <input type="text" value={orderList && orderList.IMPS[0].name} disabled={true} />
                              <img
                                data-v-80ae6ee1=""
                                src={copySVg}
                                onClick={() => { copyToClipboard(orderList.IMPS[0].name) }}
                              />
                            </div>
                          </div>
                          <div data-v-80ae6ee1="" className="clabel">
                            <label data-v-80ae6ee1="">Account Number</label>
                            <div data-v-80ae6ee1="" className="cpy-text">
                              <input type="text" value={orderList && orderList.IMPS[0].account_number} disabled={true} />
                              <img
                                data-v-80ae6ee1=""
                                src={copySVg}
                                onClick={() => { copyToClipboard(orderList.IMPS[0].account_number) }}
                              />
                            </div>
                          </div>
                          <div data-v-80ae6ee1="" className="clabel">
                            <label data-v-80ae6ee1="">IFSC Code</label>
                            <div data-v-80ae6ee1="" className="cpy-text">
                              <input type="text" value={orderList && orderList.IMPS[0].ifsc_code} disabled={true} />
                              <img
                                data-v-80ae6ee1=""
                                src={copySVg}
                                onClick={() => { copyToClipboard(orderList.IMPS[0].ifsc_code) }}
                              />
                            </div>
                          </div>
                          <div data-v-80ae6ee1="" className="clabel">
                            <label data-v-80ae6ee1="">Account Holder</label>
                            <div data-v-80ae6ee1="" className="cpy-text">

                              <input type="text" value={orderList && orderList.IMPS[0].holder_name} disabled={true} />
                              <img
                                data-v-80ae6ee1=""
                                src={copySVg}
                                onClick={() => { copyToClipboard(orderList.IMPS[0].holder_name) }}
                              />
                            </div>
                          </div>
                          <div data-v-80ae6ee1="" className="clabel">
                            <label data-v-80ae6ee1="">Enter UTR Number</label>
                            <div data-v-80ae6ee1="" className="cpy-text">
                              {/* <input type="text" value={orderList && orderList.UPI.upi_id} disabled={true} /> */}
                              <input
                                value={tmpUtrNo}
                                onChange={(e) => { handleSetUTR(e.target.value) }}

                                type="number"
                                placeholder="Please Enter UTR No 12 digit"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div data-v-80ae6ee1="" className="upi" style={{ display: TabUPI ? "" : "none" }}>
                      <div data-v-80ae6ee1="" className="df df-display">
                        <div data-v-80ae6ee1="" className="qr">
                          <img
                            data-v-80ae6ee1=""
                            src={orderList && orderList.UPI.qrcode}
                          />
                        </div>
                        <div data-v-80ae6ee1="" className="upidata width100">
                          <form data-v-80ae6ee1="">
                            <div data-v-80ae6ee1="" className="clabel">
                              <label data-v-80ae6ee1="">UPI ID</label>
                              <div data-v-80ae6ee1="" className="cpy-text">
                                <input type="text" value={orderList && orderList.IMPS[0].upiId} disabled={true} />
                                <img
                                  data-v-80ae6ee1=""
                                  src={copySVg}
                                  onClick={() => { copyToClipboard(orderList.UPI.upi_id) }}
                                />
                              </div>
                            </div>
                            <div data-v-80ae6ee1="" className="clabel">
                              <label data-v-80ae6ee1="">Enter UTR Number</label>
                              <div data-v-80ae6ee1="" className="cpy-text">
                                <input
                                  value={tmpUtrNo}
                                  onChange={(e) => { handleSetUTR(e.target.value) }}

                                  type="number"
                                  placeholder="Please Enter UTR No"
                                />
                              </div>
                            </div>
                            {/**/}
                          </form>
                        </div>
                      </div>
                      {/* <div data-v-80ae6ee1="" className="proofspan">
                        <span data-v-80ae6ee1="">Have a proof ?</span>
                      </div> */}
                      {/**/}
                    </div>
                    <div data-v-80ae6ee1="" className="btn1pad">
                      <button disabled={isSubmitDisabled} onClick={() => { handleToggle() }} data-v-80ae6ee1="" className={isSubmitDisabled ? "btn1 disabled-button" : "btn1"}>
                        Submit
                      </button>
                      <button onClick={() => { cancelOrder() }} data-v-80ae6ee1="" className="btn2">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          : <>
            <div data-v-45fa8a71="" data-v-80ae6ee1="" className="checkout-container">
              <div data-v-45fa8a71="" className="checkout text-center">
                <div data-v-45fa8a71="" className="cp text-center">
                  <p data-v-45fa8a71="" className="cpp">
                    Open Your UPI app to approve the payment request
                  </p>
                  <div data-v-45fa8a71="" className="base-timer">
                    <svg
                      data-v-45fa8a71=""
                      className="base-timer__svg"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-v-45fa8a71="" className="base-timer__circle">
                        <circle
                          data-v-45fa8a71=""
                          className="base-timer__path-elapsed"
                          cx={50}
                          cy={50}
                          r={45}
                        />
                        <path
                          data-v-45fa8a71=""
                          id="base-timer-path-remaining"
                          strokeDasharray="257 283"
                          className="base-timer__path-remaining green"
                          d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
                        />
                      </g>
                    </svg>
                    <span
                      data-v-45fa8a71=""
                      id="base-timer-label"
                      className="base-timer__label"
                    >
                      {`${minutes}:${seconds}`} <br data-v-45fa8a71="" />
                      <span data-v-45fa8a71="" className="exspan">
                        mins
                      </span>
                    </span>
                    {/* Expire Time: {orderList ? orderList.expire_at_format.split(" ")[1] + " " + orderList.expire_at_format.split(" ")[2] : null} */}
                  </div>
                  <h3 data-v-45fa8a71="" className="ch3">
                    Please approve the payment request before it times out.
                  </h3>
                  <p data-v-45fa8a71="" className="cpp">
                    Do not hit back button until the transaction is complete.
                  </p>
                  <div data-v-45fa8a71="" className="btn1pad">
                    <button onClick={() => { cancelOrder() }} data-v-45fa8a71="" className="btn1">
                      Cancel Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>

      }
    </Typography>

  )

};

export default CheckoutPage;
