import * as CryptoJS from 'crypto-js';
import jwt from 'jwt-decode' // import dependency
import api from 'api';




let _key = CryptoJS.enc.Utf8.parse('38554326552530511959024915401276');
let _iv = CryptoJS.enc.Utf8.parse('3t5rs2&ahAHJ@NcQ');

export default {
    decode: (token) => {
        try {
            let decToken = CryptoJS.AES.decrypt(token, _key, { iv: _iv, format: CryptoJS.format.Hex, keySize: 256, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding }).toString(CryptoJS.enc.Utf8);
            // console.log('return ', decToken);
            let tempToken = decToken.replace(/[^\x20-\x7E]/g, "");
            return tempToken == "" ? "Invalid key" : tempToken
        } catch (error) {
            //console.log(error);
            return "Invalid key"
        }
    },

    encrypt: (data) => {
        // var decodeKey = crypto.createHash('sha256').update(key, 'utf-8').digest();
        try {
            var cipher = CryptoJS.AES.encrypt(JSON.stringify(data), _key, { iv: _iv, keySize: 256, mode: CryptoJS.mode.CBC }).toString(CryptoJS.format.Hex);
            return cipher;
        } catch (error) {
            console.log('acipher err', error);
            return "Invalid key"
        }
    },
    decodeJWT: (token) => {
        try {
            return jwt(token);
        } catch (error) {
            //console.log(error);
            return false
        }
    },
    verifyToken: async (token) => {
        try {
            let resp = await api.accountApi.verifyToken(token);
            return resp.data.status;
        } catch (error) {
            console.error("Token verification failed:", error);
            return false;
        }
    }
}